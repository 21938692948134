import React from 'react';
import {Pressable, Text} from "react-native";
import Icon from "../Icon";
import useTimer from "../../hooks/useTimer";
import useTheme from "../../hooks/useTheme";
import {formatTimer} from "../../utils/formatTime";

function TimerButton({isMobile = true}) {

    const {timerIsRunning, timerIsLoading, startTimer, stopTimer, currentTimer} = useTimer()
    const {colors, theme} = useTheme() // Todo: Refactor

    // console.log(timerIsRunning)
    const onTimerPress = () => {
        if(timerIsRunning) {
            stopTimer()
        } else {
            startTimer()
        }
    }

    return !isMobile ? <Pressable
        style={{
            backgroundColor: colors.text,
            borderRadius: 8,
            padding: 8,
            flexDirection: 'row',
            // justifyContent: timerIsRunning ? 'space-between' : 'flex-start',
            alignItems: 'center'
        }}
        onPress={!timerIsLoading ? onTimerPress : null}
    >
        <Icon name={timerIsRunning ? 'stop' : 'play'} color={colors.background}
              size={24}/>
        <Text style={[theme.text, {
            color: colors.background,
            marginLeft: 8
        }]}>{timerIsRunning && currentTimer && !timerIsLoading ? formatTimer(currentTimer * 1000) : 'Start'}</Text>
    </Pressable> : null
}

export default TimerButton;