import React from 'react';
import {Pressable, Text} from "react-native";
import {useNavigation} from "@react-navigation/native"
import useTheme from "../../hooks/useTheme";

function Breadcrumb({name = '', navKey = '', isLast = false, params}) {
    const navigation = useNavigation()
    const {theme, colors} = useTheme()

    const title = params?.name ?? name

    return (
        <Pressable onPress={() => navigation.navigate(name, {...params})}>
            <Text
                style={[theme.textL, {color: isLast ? colors.text : colors.subtext}]}
            >
                {title}{!isLast ? ' / ' : null}
            </Text>
        </Pressable>
    )
}

export default Breadcrumb;