import React, {useEffect, useState} from 'react';
import {View, Text} from "react-native";
import Icon from "../Icon";
import {MotiView} from "moti";
import {useToastStore} from "../../stores/useToastStore";
import useTheme from "../../hooks/useTheme";


function Toast({id, type = 'share', message = '', duration = 3000, isHovered = false}) {
    const [timeStamp, setTimeStamp] = useState(Date.now())
    const [toastDuration, setToastDuration] = useState(duration)
    const [timeOutId, setTimeOutId] = useState(null)
    const {removeToast} = useToastStore()

    const {theme} = useTheme()

    const getIconName = (type) => {
        switch (type) {
            case 'info':
                return 'info'
            case 'success':
                return 'checkmark'
            default:
                return 'share'
        }
    }

    const iconName = getIconName(type)

    useEffect(() => {
        if (!isHovered) {
            setToastDuration(toastDuration - (Date.now() - timeStamp))
            const timeOutId = setTimeout(() => {
                removeToast(id)
            }, toastDuration)
            setTimeOutId(timeOutId)
        } else {
            setTimeStamp(Date.now())
            clearTimeout(timeOutId)
        }
        return clearTimeout(timeOutId)
    },[isHovered])

    return (
        <MotiView
            from={{height: 0, opacity: 0, marginVertical: 0}}
            animate={{height: 48, opacity: 1, marginVertical: 8}}
            exit={{height: 0, opacity: 0, marginVertical: 0}}
            transition={{type: 'spring', duration: 500}}
            style={{backgroundColor: '#1A237E', overflow: 'hidden', flexDirection: 'row', alignItems: 'center', paddingHorizontal: 16, borderRadius: 24}}
        >
            <View style={{flex: 1}}>
                <Text style={[theme.text, {color: '#fff'}]}>{message}</Text>
            </View>
            <MotiView
                from={{scale: 0}}
                animate={{scale: 1}}
                transition={{type: 'spring', duration: 1000}}
                style={{backgroundColor: '#fff', width: 20, height: 20, borderRadius: '100%', justifyContent: 'center', alignItems: 'center'}}
            >
                <Icon name={iconName} size={16} style={{color: '#1A237E'}} />
            </MotiView>
        </MotiView>
    );
}

export default Toast;