import useTheme from "../../../hooks/useTheme";

const useInputTheme = () => {
    const {theme, colors, spacing, borderRadius} = useTheme()
    const inputTheme = {
        ...theme,
        input: {
            color: colors.text,
            backgroundColor: colors.background,
            padding: spacing.m,
            borderRadius: borderRadius.m,
            borderWidth: 1,
            borderColor: colors.border,
            marginVertical: spacing.s,
        },
        inputText: {
            color: colors.text,
        }
    }

    return {inputTheme, colors}
}

export default useInputTheme