import {useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getRunningTrack, postTrack, stopTrack} from "../api/api";
import {useToastStore} from "../stores/useToastStore";

const useTimer = () => {
    const [currentTimer, setCurrentTimer] = useState(0)
    const {addToast} = useToastStore()

    const queryClient = useQueryClient()
    // Todo: Fetcht nicht neu, wenn Query invalidiert wird
    const {data = {}, isLoading = true, refetch} = useQuery({queryKey: ['currentTrack'], queryFn: getRunningTrack})

    const timerIsRunning = !!data?.is_running
    const timerIsLoading = isLoading

    const stopTrackMutation = useMutation({
        mutationFn: stopTrack,
        onSuccess: () => {
            refetch()
            queryClient.invalidateQueries({queryKey: ['tracks', null]})
        },
        onError: () => {
            addToast({type: 'error', message: 'Could not stop track'})
        }
    })

    const postTrackMutation = useMutation({
        mutationFn: postTrack,
        onSuccess: () => {
            refetch()
            queryClient.invalidateQueries({queryKey: ['tracks', null]})
        },
        onError: () => {
            addToast({type: 'error', message: 'Could not start track'})
        }
    })

    const startTimer = () => {
        postTrackMutation.mutate()
    }

    const stopTimer = () => {
        stopTrackMutation.mutate()
    }

    useEffect(() => {
        let interval: NodeJS.Timer = null
        if (timerIsRunning) {
            interval = setInterval(() => {
                // Todo: optimize this - or not hehe
                const now = new Date().getTime()
                const startTime = new Date(data?.started_at).getTime()
                const diff = now - startTime
                setCurrentTimer(diff / 1000)
            }, 100)
        } else if (!timerIsRunning && currentTimer !== 0) {
            clearInterval(interval)
        }
        return () => clearInterval(interval)
    }, [timerIsRunning])

    return {timerIsRunning, timerIsLoading, startTimer, stopTimer, currentTimer}
}

export default useTimer