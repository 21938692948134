import React, {useEffect, useState} from 'react';
import {Pressable, View} from "react-native";
import {useToastStore} from "../../stores/useToastStore";
import Toast from "./Toast";
import {AnimatePresence} from "moti";

function Toasts() {
    const {toasts} = useToastStore()
    const [isHovered, setIsHovered] = useState(false)

    return <View
        style={{flex: 1, width: '100%', justifyContent: 'flex-end', paddingVertical: 8}}
    >
        <Pressable
            onHoverIn={() => setIsHovered(true)}
            onHoverOut={() => setIsHovered(false)}
        >
            <AnimatePresence>
                {toasts.map(({id, ...toastProps}, i) => (
                    <Toast
                        key={id}
                        id={id}
                        {...toastProps}
                        isHovered={isHovered}
                    />
                ))}
            </AnimatePresence>
        </Pressable>
    </View>
}

export default Toasts;