import React from 'react';
import Button from "../../components/Button";
import {View} from "react-native";
import {useNavigation} from "@react-navigation/native";

function OverviewHeaderButtons(props) {
    const navigation = useNavigation()

    return <View style={{flexDirection: 'row'}}>
        <Button label='New Project' icon='plus' style={{marginRight: 12}} onPress={() => navigation.navigate('CreateProject')}/>
        <Button label='New Task' icon='plus' onPress={() => navigation.navigate('CreateTask')} />
    </View>
}

export default OverviewHeaderButtons;