import {useEffect, useState} from "react";
import {getUserData} from "../api/user";
import {useNavigation} from "@react-navigation/native";

const useLogin = () => {
    const [token, setToken] = useState(null)
    const [loading, setLoading] = useState(true)

    // Todo: Refactor, irgendwie alles kagge :D

    useEffect(() => {
        refreshLogin()
    }, [])
    const getToken = async () => {
        const userData = await getUserData()
        const {token} = userData
        setToken(token)
        setLoading(false)
        return token
    }

    const refreshLogin = async () => {
        setLoading(true)
        getToken()
    }

    const isLoggedIn = async () => {
        return !!await getToken()
    }

    return {isLoggedIn, refreshLogin}
}

export default useLogin