import React, {useEffect} from 'react'
import {Text, View} from "react-native"
import useTheme from "../../hooks/useTheme"

const Overview = ({navigation}) => {
    const {theme} = useTheme()

    useEffect(() => {
        navigation.setParams({name: 'Overview'})
    }, [])

    return (
        <View style={theme.container}>
            <Text style={theme.text}>Overview</Text>
        </View>
    )
}

export default Overview