import React from 'react';
import {Text, View} from "react-native";
import useTheme from "../../hooks/useTheme";
import Breadcrumb from "./Breadcrumb";

function Header({options, navigation}) {
    const {theme} = useTheme()
    const {headerRight} = options
    const HeaderRight = () => headerRight ? headerRight() : null

    const state = navigation.getState()
    const {routes} = state

    return (
        <View style={[theme.header, options.headerStyle]}>
            <View style={{flexDirection: 'row'}}>
                {routes.map((route, i) => (
                    <Breadcrumb key={i} navKey={route.key} i={i} {...route} isLast={i === routes.length - 1}/>
                ))}
            </View>
            <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-end'}}>
                <HeaderRight/>
            </View>
        </View>
    )
}

export default Header;