import React from 'react';
import {View} from "react-native";
import useTheme from "../../hooks/useTheme";

function Grid({cols = 1, gapHorizontal = null, gapVertical = null, style ={}, children = [], ...otherProps}) {
    const {spacing} = useTheme()
    const gap = {horizontal: gapHorizontal ?? spacing.xl, vertical: gapVertical ?? spacing.xl}
    return (
        <View style={[{width: '100%'}, style]} {...otherProps}>
            <View style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginHorizontal: -gap.horizontal / 2,
                marginVertical: -gap.vertical / 2
            }}>
                {
                    children?.map((child, i) => <View
                            key={i}
                            style={{
                                width: `${100 / cols}%`,
                                paddingVertical: gap.vertical / 2,
                                paddingHorizontal: gap.horizontal / 2
                            }}
                        >
                            {child}
                        </View>
                    )
                }
            </View>
        </View>
    );
}

export default Grid;