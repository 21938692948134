import useTheme from "../../../hooks/useTheme";

const useButtonTheme = () => {
    const {theme, colors, spacing, borderRadius} = useTheme();
    const buttonTheme = {
        ...theme,
        button: {
            padding: 1,
            background: 'linear-gradient(180deg, #EDEDED 0%, #FFFFFF 100%)',
            marginVertical: spacing.s,
            borderRadius: 4,
        },
        buttonInner: {
            background: 'linear-gradient(180deg, #F5F5F5 39.58%, #FFFFFF 100%)',
            boxShadow: '0px 0px 0.5px rgba(0, 0, 0, 0.4), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.5), 0px 0px 0px 1.5px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.15)',
            borderRadius: 3, // wieso nicht 4, M
            paddingHorizontal: spacing.m,
            paddingVertical: spacing.s,
            flexDirection: 'row',
            alignItems: 'center',
        },
        text: {
            ...theme.text,
            fontWeight: '700',
            marginHorizontal: spacing.s,
        },
    }
    return {buttonTheme, colors, spacing, borderRadius};
}

export default useButtonTheme;