import React from 'react';
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import Projects from "./Projects";
import CreateProject from "../Shared/CreateProject";
import useTheme from "../../hooks/useTheme";
import Project from "../Shared/Project";
import Button from "../../components/Button";
import Header from "../../components/Header";

const Stack = createNativeStackNavigator();

function ProjectStack({navigation}) {
    const {screenProps, colors} = useTheme()
    return (
        <Stack.Navigator
            initialRouteName={'Projects'}
            screenOptions={{
                header: props => <Header {...props}/>,
                ...screenProps
            }}
        >
            <Stack.Screen name="Projects" component={Projects} options={{
                headerRight: () => (
                    <Button label='New Project'
                            icon='plus'
                            onPress={
                                () => navigation.navigate('CreateProject')
                            }
                    />
                ),
            }}/>
            <Stack.Screen name="Project" component={Project}/>
            <Stack.Screen name="CreateProject" component={CreateProject} options={{title: 'New Project'}}/>
        </Stack.Navigator>
    );
}

export default ProjectStack;