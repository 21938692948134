import useTheme from "../../../hooks/useTheme";

const useSelectTheme = () => {
    const {theme, colors, spacing, borderRadius} = useTheme()
    const selectTheme = {
        ...theme,
        dropdown: {
            height: 48,
            color: colors.text,
            backgroundColor: colors.background,
            paddingHorizontal: spacing.m,
            borderRadius: borderRadius.m,
            borderWidth: 1,
            borderColor: colors.border,
            marginVertical: spacing.s,
            flexDirection: 'row',
            alignItems: 'center',
        },
        dropdownEntry: {
            height: 48,
            borderBottomWidth: 1,
            borderBottomColor: colors.border,
            backgroundColor: colors.background,
            paddingHorizontal: spacing.m,
            flexDirection: 'row',
            alignItems: 'center',
        },
        selectText: {
            color: colors.text,
        }
    }

    return {selectTheme, colors}
}

export default useSelectTheme