import React from 'react';
import {Pressable, Text, View} from "react-native";
import useTheme from "../../hooks/useTheme"
import {formatDuration, formatTime} from "../../utils/formatTime"
import Icon from "../Icon";

function SingleTrack({onPress = () => null, track, isLast = false}) {
    const {theme, colors, spacing} = useTheme()
    const {color = '#000', project, task, duration, is_running, started_at, ended_at} = track

    return (
        <Pressable
            style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 16,
            paddingVertical: 8,
            backgroundColor: colors.background,
            borderBottomColor: colors.border,
            borderBottomWidth: isLast ? 0 : 1
        }}
            onPress={onPress}
        >
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <View style={{width: 8, height: 8, backgroundColor: color, borderRadius: 4, marginRight: 8}}/>
                <View>
                    {task ? <Text style={[theme.text, {marginVertical: spacing.xs}]}>{task.title}</Text> : null}
                    {project ? <Text style={[theme.textS, {
                        color: colors.subtext,
                        marginVertical: spacing.xs
                    }]}>{project.title}</Text> : <Text style={[theme.text, {
                        color: colors.subtext,
                        marginVertical: spacing.xs,
                        fontStyle: 'italic'
                    }]}>Not assigned</Text>}
                </View>
            </View>
            <View style={{flex: 1}}>
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {is_running ?
                        <Icon name="is-running" size={16} color={colors.text} style={{marginRight: spacing.s}}/> : null}
                    <Text style={[theme.text, {marginVertical: spacing.xs}]}>{formatDuration(duration * 1000)}</Text>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <Text style={[theme.textS, {
                        color: colors.subtext,
                        marginVertical: spacing.xs
                    }]}>
                        {ended_at ? `${formatTime(started_at)} - ${formatTime(ended_at)}` : `started at ${formatTime(started_at)}`}
                    </Text>
                </View>
            </View>
            <Icon name="chevron-right" size={16} style={{marginHorizontal: 8}} color={colors.subtext}/>
        </Pressable>
    );
}

export default SingleTrack;