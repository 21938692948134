import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"

dayjs.extend(duration)

// Todo: Localize
const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

const formatDate = (date) => {
    const dateObj = dayjs(date)

    const dayIndex = dateObj.day()
    const monthIndex = dateObj.month()

    const weekDay = weekDays[dayIndex]
    const month = months[monthIndex]

    return `${weekDay} ${dateObj.date()}. ${month}`
}

const formatTime = (date) => {
    const dateObj = dayjs(date)
    return dateObj.format('HH:mm')
}

const formatDuration = (duration) => {
    const durationObj = dayjs.duration(duration)
    const hours = durationObj.hours()
    const minutes = durationObj.format('mm')
    return `${hours}:${minutes} h` // Todo: Localize
}

const formatTimer = (duration) => {
    const durationObj = dayjs.duration(duration)
    const hours = durationObj.format('HH')
    const minutes = durationObj.format('mm')
    const seconds = durationObj.format('ss')
    return `${hours}:${minutes}:${seconds}` // Todo: Localize
}

export {formatDate, formatDuration, formatTimer, formatTime}