import {create} from "zustand"

interface ToastStore {
    toasts: Array<Toast>,
    addToast: (toast: Toast) => void,
    removeToast: (id: number) => void,
}

interface Toast {
    id: number,
    type: 'info' | 'success' | 'error',
    message: string,
    duration: number,
}

export const useToastStore = create<ToastStore>((set, get) => ({
    toasts: [],
    addToast: async ({type = 'share', message = '', duration = 3000}) => {
        const id = Date.now()
        set(state => ({toasts: [...state.toasts, {id, type, message, duration}]}))
    },
    // todo: entfernt falschen toast?
    removeToast: (id) => {
        const toasts = get().toasts
        const newToasts = toasts.filter(toast => toast.id !== id)
        set({toasts: newToasts})
    }
}))