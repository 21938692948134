import React, {useEffect} from 'react';
import useTheme from "../hooks/useTheme";
import tabNavScreenOptions from "../navigation/tabNavScreenOptions";
import OverviewStack from "./Overview/OverviewStack";
import Tracks from "./Tracks";
import ProjectStack from "./Projects/ProjectStack";
import Profile from "./Profile";
import {createResponsiveTabBar} from "../navigation/ResponsiveTabBar";
import useLogin from "../hooks/useLogin";
import Login from "./Login";
import TrackStack from "./Tracks/TrackStack";

const Tab = createResponsiveTabBar()
const Tabs = ({navigation}) => {
    const {colors, theme} = useTheme()

    return <Tab.Navigator
        screenOptions={({route}) => ({
            ...tabNavScreenOptions({colors}),
            title: route?.params?.name,
        })}
    >
        <Tab.Screen name="Overview" component={OverviewStack}/>
        <Tab.Screen name="Tracks" component={TrackStack}/>
        <Tab.Screen name="Projects" component={ProjectStack}/>
        <Tab.Screen name="Profile" component={Profile}/>
    </Tab.Navigator>
}

export default Tabs;