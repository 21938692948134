import config from '../config'
import {getUserData} from "./user";
import axios from 'axios'
import {Platform} from "react-native";

const {create} = axios

// Wird für Public Routes ohne den Interceptor genutzt (z.B. Login)
const publicApi = create({
    baseURL: `${config.siteURL}/api/v1`,
})

// define the api
const api = create({
    baseURL: `${config.siteURL}/api/v1`,
})

api.interceptors.request.use(
    async (config) => {
        const {token} = await getUserData()
        if (token) {
            const {headers} = config
            config.headers = {
                ...headers,
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            }
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

const getLogin = ({email, password}) => {
    const data = {device_name: `Time-Tracker ${Platform.OS}`, email, password}
    return publicApi.post(`login`, data).then((res) => res.data)
}

const getProjects = ({team = false}) => {
    return team ? api.get(`teams/${team}/projects`).then((res) => res.data) : api.get(`/projects`).then((res) => res.data)
}

const getProject = ({slug}) => {
    return api.get(`/projects/${slug}`).then((res) => res.data)
}

const postProject = (data = {}) => {
    return api.post(`/projects`, data).then((res) => res.data)
}

const postTask = (data = {}) => {
    return api.post(`/tasks`, data).then((res) => res.data)
}

const getTrack = ({id}) => {
    return api.get(`/tracks/${id}`).then((res) => res.data)
}

const getTracks = () => {
    return api.get(`/tracks`).then((res) => res.data)
}

const getRunningTrack = () => {
    return api.get(`/tracks/running`).then((res) => res.data)
}

const postTrack = (data: object = {}) => {
    return api.post(`/tracks`, data).then((res) => res.data)
}

const updateTrack = ({id, data = {}}) => {
    return api.patch(`/tracks/${id}`, data).then((res) => res.data)
}

const stopTrack = () => {
    return api.post(`/tracks/stop`).then((res) => res.data)
}

export {getLogin, getProjects, getProject, postProject, postTask, getTrack, getTracks, getRunningTrack, postTrack, updateTrack, stopTrack}