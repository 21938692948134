import React, {useState} from 'react';
import {KeyboardAvoidingView, SafeAreaView, Text, View} from "react-native";
import {useThemeSettings} from "../stores/useThemeSettings";
import useLogin from "../hooks/useLogin";
import Input from "../components/Input";
import Button from "../components/Button";
import {getLogin} from "../api/api";
import {setUserData} from "../api/user";
import {useNavigation} from "@react-navigation/native";
import useTheme from "../hooks/useTheme";
import {useQueryClient} from "@tanstack/react-query";

function Login({initialEmail = ''}) {
    const {theme, colors} = useTheme()
    const navigation = useNavigation()
    const {refreshLogin} = useLogin()

    const queryClient = useQueryClient()

    const [email, setEmail] = useState(initialEmail)
    const [password, setPassword] = useState('')

    return (
        <SafeAreaView>
            <KeyboardAvoidingView>
                <View style={theme.container}>
                    <Text style={theme.title}>Bitte log dich ein</Text>
                    <Input
                        editable
                        placeholder="E-Mail Adresse"
                        onChangeText={setEmail}
                        value={email}
                        autoCapitalize="none"
                        autoComplete="email"
                        textContentType="emailAddress"
                        keyboardType="email-address"
                    />
                    <Input
                        editable
                        placeholder="Passwort"
                        onChangeText={setPassword}
                        value={password}
                        secureTextEntry
                    />
                    <Button onPress={() => {
                        getLogin({email, password})
                            .then((data) => {
                                console.log(data)
                                const {token} = data
                                setUserData({token}).then(() => {
                                    queryClient.invalidateQueries()
                                    refreshLogin()
                                    navigation.navigate('Tabs')
                                })
                            })
                            .catch((error) => {
                                console.error(error)
                            })
                    }} label='Einloggen'/>
                </View>
            </KeyboardAvoidingView>
        </SafeAreaView>
    );
}

export default Login;