import React from 'react';
import {Text, View} from "react-native";
import useTheme from "../../hooks/useTheme";
import SingleTrack from "./SingleTrack";
import {formatDate, formatDuration} from "../../utils/formatTime"
import {useNavigation} from "@react-navigation/native";

function TrackDate({trackDate}) {
    const {theme} = useTheme() // Todo: is this the smartest way to go about things? I'd rather pass down a small object
    const {tracks, date} = trackDate

    const navigation = useNavigation()

    const dateDuration = (tracks) => {
        let totalDuration = 0
        tracks.forEach(track => {
            totalDuration += track.duration ?? 0
        })
        return totalDuration
    }

    const totalDuration = dateDuration(tracks)

    return (
        <View style={theme.track}>
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Text style={theme.title}>{formatDate(date)}</Text>
                <Text style={theme.textL}>{formatDuration(totalDuration * 1000)}</Text>
            </View>
            <View style={{flexDirection: 'row', marginVertical: 8}}>
                {tracks.map((track, index) => {
                    const {duration = 0} = track
                    return (
                        <View key={index} style={{
                            flex: duration / totalDuration,
                            minWidth: 8, // in case a track is around 0
                            height: 8,
                            borderRadius: 4,
                            marginHorizontal: 2,
                            backgroundColor: track.color ?? '#000'
                        }}/>
                    )
                })}
            </View>
            <View style={{overflow: 'hidden', borderRadius: 8}}>
                {tracks.map((track, index) => {
                    return (
                        <SingleTrack
                            key={index}
                            track={track}
                            isLast={index === tracks.length - 1}
                            onPress={() => navigation.navigate('Track', {id: track.id})}
                        />
                    )
                })}
            </View>
        </View>
    )
}

export default TrackDate;