import React from 'react';
import {ScrollView, Text, View} from "react-native";
import useTheme from "../../hooks/useTheme";
import TrackDate from "../../components/TrackDate";
import {getTracks} from "../../api/api";
import {useQuery} from "@tanstack/react-query";

function Tracks({}) {
    const teamId = null
    const {theme} = useTheme()

    const {data = [], isLoading = true} = useQuery({queryKey: ['tracks', teamId], queryFn: getTracks})

    return isLoading ? null : (
        <ScrollView>
            <View style={theme.container}>
                {data.map((trackDate, index) => <TrackDate key={index} trackDate={trackDate}/>)}
            </View>
        </ScrollView>
    );
}

export default Tracks;