import React from 'react';
import {TabActions} from "@react-navigation/native";
import {Image, Pressable, Text} from "react-native";
import Icon from "../components/Icon";
import useTheme from "../hooks/useTheme";
import Animated, {Easing, useAnimatedStyle, useSharedValue, withTiming} from "react-native-reanimated";

function TabBarItem({
                        route,
                        state,
                        navigation,
                        descriptors,
                        isActive = false,
                        isMobile,
                        tabBarActiveBackgroundColor,
                        tabBarActiveTintColor,
                        tabBarInactiveTintColor
                    }) {
    const {theme} = useTheme()
    const color = isActive ? tabBarActiveTintColor : tabBarInactiveTintColor
    const scale = useSharedValue(1)

    const scaleStyle = useAnimatedStyle(() => {
        return {
            transform: [{scale: withTiming(scale.value, {duration: 100, easing: Easing.ease})}]
        }
    })

    const getIconName = (name) => {
        switch (name) {
            case 'Tracks':
                return 'tracks'
            case 'Projects':
                return 'projects'
            default:
                return 'overview'
        }
    }

    const iconName = getIconName(route.name)

    const TabIcon = () => route.name === 'Profile' ?
        <Image
            style={theme.tabBarProfileImage}
            source={{uri: 'https://placekitten.com/48/48'}}
        /> :
        <Icon name={iconName} color={color} size={24}/>

    return <Animated.View style={scaleStyle}>
        <Pressable
            style={[isMobile ? theme.tabBarItemMobile : theme.tabBarItem, isActive && !isMobile ? {backgroundColor: tabBarActiveBackgroundColor} : {}, route.name === 'Profile' && !isMobile ? {order: -1} : {}]}
            key={route.key}
            onPressOut={() => scale.value = 1}
            onPressIn={() => {
                scale.value = 0.95

                const event = navigation.emit({
                    type: 'tabPress',
                    target: route.key,
                    canPreventDefault: true,
                });

                if (!event.defaultPrevented) {
                    navigation.dispatch({
                        ...TabActions.jumpTo(route.name),
                        target: state.key,
                    });
                }
            }}
        >
            <TabIcon/>
            {!isMobile ? <Text
                style={[theme.text, {
                    color,
                    marginLeft: 8
                }]}>{descriptors[route.key].options.title || route.name}</Text> : null}
        </Pressable>
    </Animated.View>
}

export default TabBarItem;