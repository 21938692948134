import React from 'react';
import {Pressable, Text, View} from "react-native";
import useButtonTheme from "./hooks/useButtonTheme";
import Icon from "../Icon";

function Button({onPress = null, icon, label, style = {}, ...otherProps}) {

    const {buttonTheme} = useButtonTheme()

    return (
        <Pressable onPress={onPress} style={[buttonTheme.button, style]} {...otherProps}>
            <View style={buttonTheme.buttonInner}>
                {icon ? <Icon name={icon} size={16} style={buttonTheme.icon}/> : null}
                {label ? <Text style={[buttonTheme.text]}>{label}</Text> : null}
            </View>
        </Pressable>
    );
}

export default Button;