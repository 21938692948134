import React, {useEffect, useState} from 'react'
import {Pressable, Text, View} from 'react-native'
import Icon from "../Icon";
import useTheme from "../../hooks/useTheme";
import useSelectTheme from "./hooks/useSelectTheme";

function Select({
                    label,
                    style = {},
                    entries,
                    selectedValue,
                    setValue,
                    disabled = false,
                }) {
    const [expanded, setExpanded] = useState(false)
    const renderDropDownEntries = []

    const {selectTheme, colors} = useSelectTheme()

    useEffect(() => {
        if (disabled) {
            setExpanded(false)
        }
    }, [disabled])

    entries.forEach((entry, index) => {
        const isActive = entry.value === selectedValue.value
        const isFirst = index === 0
        const isLast = index === entries.length - 1

        renderDropDownEntries.push(
            <Pressable
                style={[
                    selectTheme.dropdownEntry,
                    isFirst
                        ? {borderTopLeftRadius: 8, borderTopRightRadius: 8}
                        : isLast
                            ? {borderBottomLeftRadius: 8, borderBottomRightRadius: 8}
                            : {},
                    isLast ? {borderBottomWidth: 0} : {},
                ]}
                key={index}
                onPress={() => {
                    setValue(entry)
                    setExpanded(false)
                }}
                disabled={disabled}
            >
                <Text style={[selectTheme.selectText, isActive ? {color: colors.primary} : {}]}>
                    {entry.label}
                </Text>
            </Pressable>
        )
    })

    useEffect(() => {
        entries[0] ? setValue(entries[0]) : null
    }, [entries])

    return (
        <>
            {expanded ? (
                <Pressable
                    style={[selectTheme.absolute, {zIndex: 1}]}
                    onPress={() => setExpanded(false)}
                    disabled={disabled}
                />
            ) : null}
            <View
                style={[{marginHorizontal: 8, marginVertical: 8, zIndex: 1}, style]}
            >
                {label ? <Text style={selectTheme.inputLabel}>{label}</Text> : null}
                <Pressable
                    style={[selectTheme.dropdown, {opacity: disabled ? 0.5 : 1}]}
                    onPress={() => setExpanded(!expanded)}
                    disabled={disabled}
                >
                    <View style={{flex: 1}}>
                        <Text style={[selectTheme.selectText]}>{selectedValue.label}</Text>
                    </View>
                    <Icon name="chevron-down" size={16} color={colors.subtext}/>
                </Pressable>
                <View
                    style={{
                        marginTop: 1,
                        position: 'absolute',
                        top: '100%',
                        width: '100%',
                        borderRadius: 8,
                        shadowColor: '#000',
                        shadowRadius: 8,
                        shadowOpacity: 0.1,
                        shadowOffset: {height: 8},
                        elevation: 3,
                    }}
                >
                    {expanded ? renderDropDownEntries : null}
                </View>
            </View>
        </>
    )
}

export default Select
