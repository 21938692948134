import AsyncStorage from '@react-native-async-storage/async-storage'

const getUserData = async () => {
    const token = await AsyncStorage.getItem('token')
    return { token }
}

const setUserData = async ({ token }) => {
    try {
        await AsyncStorage.setItem('token', token)
    } catch (e) {
        console.log(e)
    }
    return
}

const removeUserData = async () => {
    try {
        await AsyncStorage.removeItem('token')
    } catch (e) {
        console.log(e)
    }
}

export { getUserData, setUserData, removeUserData }