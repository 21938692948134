import * as React from 'react';
import {Text, Pressable, View, Image, SafeAreaView, Platform} from 'react-native'
import {
    NavigationHelpersContext,
    useNavigationBuilder,
    createNavigatorFactory,
    TabRouter,
    TabActions,
} from '@react-navigation/native'
import useTheme from "../hooks/useTheme"
import Icon from "../components/Icon"
import TimerButton from "../components/TimerButton";
import TabBarItem from "./TabBarItem";
import Toasts from "../components/Toasts";

const TabBar = ({children, ...otherProps}) => Platform.OS === 'web' ?
    <div {...otherProps} data-tauri-drag-region>{children}</div> : <View {...otherProps}>{children}</View>


function ResponsiveTabBar({
                              initialRouteName,
                              children,
                              screenOptions,
                              tabBarStyle,
                              contentStyle,
                          }) {
    const {state, navigation, descriptors, NavigationContent} =
        useNavigationBuilder(TabRouter, {
            children,
            screenOptions,
            initialRouteName,
        })

    const {theme, colors, spacing, borderRadius, isMobile} = useTheme()

    const contentSpacing = isMobile ? 0 : spacing.m
    const contentBorderRadius = isMobile ? 0 : borderRadius.m

    return (
        <NavigationContent>
            <SafeAreaView style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                flexDirection: isMobile ? 'column-reverse' : 'row'
            }}>
                <TabBar style={isMobile ? theme.tabBarMobile : theme.tabBar}>
                    <View
                        style={{flexDirection: isMobile ? 'row' : 'column', width: '100%'}}>
                        {state.routes.map((route, i) => {

                                const {
                                    tabBarActiveBackgroundColor,
                                    tabBarActiveTintColor,
                                    tabBarInactiveTintColor,
                                } = screenOptions(route.name)

                                return <TabBarItem
                                    key={i}
                                    route={route}
                                    state={state}
                                    navigation={navigation}
                                    descriptors={descriptors}
                                    isActive={state.index === i}
                                    isMobile={isMobile}
                                    tabBarActiveBackgroundColor={tabBarActiveBackgroundColor}
                                    tabBarActiveTintColor={tabBarActiveTintColor}
                                    tabBarInactiveTintColor={tabBarInactiveTintColor}
                                />
                            }
                        )}
                    </View>
                    <Toasts/>
                    <TimerButton isMobile={isMobile}/>
                </TabBar>
                <View style={[{flex: 1}, contentStyle]}>
                    {state.routes.map((route, i) => {
                        return (
                            <View
                                key={route.key}
                                style={[
                                    {
                                        display: i === state.index ? 'flex' : 'none',
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        // borderRadius: contentBorderRadius,
                                        backgroundColor: colors.subbg,
                                        overflow: 'hidden'
                                    },
                                ]}
                            >
                                {descriptors[route.key].render()}
                            </View>
                        );
                    })}
                </View>
            </SafeAreaView>
        </NavigationContent>
    );
}

export const createResponsiveTabBar = createNavigatorFactory(ResponsiveTabBar);