import React from 'react';
import {Image, Pressable, Text, View} from "react-native";
import useTheme from "../../hooks/useTheme";
import {useNavigation} from "@react-navigation/native";

function ProjectTile({project, onPress}) {
    const {theme, colors, borderRadius, spacing} = useTheme()
    const {title, description} = project

    return (
        <Pressable
            style={{
                width: '100%',
                backgroundColor: colors.bg.primary,
                borderRadius: borderRadius.m,
                overflow: 'hidden',
                borderWidth: 1,
                borderColor: colors.border
            }}
            onPress={onPress}
        >
            <Image source={{uri: 'https://placekitten.com/1024/600'}} style={{width: '100%', height: 100}}/>
            <View style={{padding: spacing.m}}>
                {title ? <Text style={theme.title}>{title}</Text> : null}
                {/*{description ? <Text style={theme.text}>{description}</Text> : null}*/}
            </View>
        </Pressable>
    );
}

export default ProjectTile;