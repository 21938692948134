import React, {useState} from 'react';
import {Pressable, Text, View} from "react-native";
import useTheme from "../hooks/useTheme";
import {removeUserData, setUserData} from "../api/user";
import useLogin from "../hooks/useLogin";
import Button from "../components/Button";
import {useThemeSettings} from "../stores/useThemeSettings";
import {useToastStore} from "../stores/useToastStore";
import Select from "../components/Select/Select";

const randomToastMessages = [
    'Jetzt wird abgetoastet!',
    'Bitte kross',
    'Mindestens Stufe 4',
    'Grilled Cheese Sandwich ❤️',
    'Dick Nutella drauf'
]

const themes = [
    {label: 'Light', value: 'light'},
    {label: 'Dark', value: 'dark'},
    {label: 'Auto', value: 'auto'},
]

function Profile({navigation}) {
    const {theme} = useTheme()
    const {addToast} = useToastStore()
    const {userThemePreference, setUserThemePreference} = useThemeSettings()
    const {refreshLogin} = useLogin()

    const activeTheme = themes.find(({value}) => value === userThemePreference)

    return (
        <View style={theme.container}>
            <Text style={[theme.title, {textAlign: 'center'}]}>Du bist eingeloggt. Hier könnte jetzt was
                Sinnvolles stehen.</Text>
            <Button label='Lieber wieder ausloggen' onPress={() => {
                // Todo: needs Refactor
                removeUserData()
                refreshLogin()
                navigation.navigate('Login')
            }}/>

            <Select
                label={'Theme'}
                entries={themes}
                setValue={({value}) => setUserThemePreference(value)}
                selectedValue={{value: userThemePreference, label: activeTheme?.label}}
                style={{width: '100%'}}
            />

            <Button label={'Mach ma Toast'}
                    onPress={() => addToast({message: randomToastMessages[Math.floor(Math.random() * randomToastMessages.length)]})}/>
        </View>
    )
}

export default Profile;