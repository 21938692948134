import React from 'react';
import {Text, View} from "react-native";
import useTheme from "../../hooks/useTheme";

function Track({navigation, route}) {
    const {id} = route.params
    const {theme} = useTheme()

    return (
        <View style={theme.container}>
            <Text style={theme.textL}>Todo 🫠</Text>
        </View>
    );
}

export default Track;