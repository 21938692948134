import React from 'react';
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import useTheme from "../../hooks/useTheme";
import Header from "../../components/Header";
import Projects from "../Projects/Projects";
import Button from "../../components/Button";
import Project from "../Shared/Project";
import CreateProject from "../Shared/CreateProject";
import Tracks from "./Tracks";
import CreateTrack from "../Shared/CreateTrack";
import Track from "../Shared/Track";

const Stack = createNativeStackNavigator();

function TrackStack({navigation}) {
    const {screenProps, colors} = useTheme()
    return (
        <Stack.Navigator
            initialRouteName={'Projects'}
            screenOptions={({route}) => ({
                header: props => <Header {...props}/>,
                title: route?.params?.name,
                ...screenProps
            })}
        >
            <Stack.Screen name="Tracks" component={Tracks} options={{
                headerRight: () => (
                    <Button label='New Track'
                            icon='plus'
                            onPress={
                                () => navigation.navigate('CreateTrack')
                            }
                    />
                ),
            }}/>
            <Stack.Screen name="Track" component={Track}/>
            <Stack.Screen name="CreateTrack" component={CreateTrack} options={{title: 'New Track'}}/>
        </Stack.Navigator>
    );
}

export default TrackStack;