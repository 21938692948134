import {StyleSheet, useColorScheme, useWindowDimensions} from "react-native";
import {useThemeSettings} from "../stores/useThemeSettings";
import useTauri from "./useTauri";

const useTheme = () => {
    const {isTauri} = useTauri()
    const {userTheme} = useThemeSettings()
    const dimensions = useWindowDimensions()

    const isMobile = dimensions.width < 768

    const getColors = () => {
        switch (userTheme()) {
            // Todo placeholder colors
            case 'dark':
                return {
                    text: '#fff',
                    subtext: '#b9b9b9',
                    bg: {
                        primary: '#000',
                        secondary: '#171717',
                        transparent: 'rgba(0,0,0,0.7)',
                    },
                    background: '#000',
                    subbg: '#171717',
                    accent: '#f00',
                    primary: '#303cb2',
                    secondary: '#1A237E',
                    card: '#000',
                    border: '#2c2c2c',
                    notification: '#f00',
                }
            default:
                return {
                    text: '#000',
                    subtext: '#808080',
                    bg: {
                        primary: '#fff',
                        secondary: '#f8f8f8',
                        transparent: 'rgba(255,255,255,0.7)',
                    },
                    background: '#fff',
                    subbg: '#f8f8f8',
                    accent: '#f00',
                    primary: '#303cb2',
                    secondary: '#4754cc',
                    card: '#fff',
                    border: '#e0e0e0',
                    notification: '#f00',
                }
        }
    }

    const colors = getColors()

    const sizes = {
        text: {
            s: 11,
            m: 13,
            l: 15,
            xl: 17,
        },
        containerWidth: 480,
        containerWideWidth: 800,
    }

    const spacing = {
        xs: 4,
        s: 8,
        m: 16,
        l: 24,
        xl: 32,
    }

    const borderRadius = {
        s: 4,
        m: 8,
        l: 12,
    }

    const containerProps = {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: spacing.m,
        width: '100%',
        marginHorizontal: 'auto',
    }

    const theme = StyleSheet.create({
        container: {
            ...containerProps,
            maxWidth: sizes.containerWidth,
        },
        containerWide: {
            ...containerProps,
            maxWidth: sizes.containerWideWidth,
        },
        absolute: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
        text: {
            color: colors.text,
            fontSize: sizes.text.m,
            fontWeight: '600'
        },
        textS: {
            color: colors.text,
            fontSize: sizes.text.s,
            fontWeight: '600'
        },
        textL: {
            color: colors.text,
            fontSize: sizes.text.l,
            fontWeight: '600'
        },
        title: {
            color: colors.text,
            fontWeight: '700',
            fontSize: sizes.text.l,
        },
        tabBarProfileImage: {
            width: 24,
            height: 24,
            borderRadius: 12,
        },
        track: {
            width: '100%',
            marginBottom: spacing.xl,
        },
        tabBar: {
            // needs a few more styles to work with div as a container
            // Todo: Maybe find a better solution than using a div
            display: 'flex',
            width: 220,
            padding: spacing.m,
            paddingTop: isTauri ? spacing.xl : spacing.m,
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            boxSizing: 'border-box',
        },
        tabBarMobile: {
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            height: 60,
        },
        tabBarItem: {
            flexDirection: 'row',
            alignItems: 'center',
            padding: spacing.s,
            marginVertical: 2,
            borderRadius: borderRadius.m,
        },
        tabBarItemMobile: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
        },
        header: {
            backgroundColor: colors.bg.secondary, borderBottomColor: colors.border, borderBottomWidth: 1, height: 60, alignItems: 'center', flexDirection: 'row', padding: spacing.m
        },
        inputLabel: {
            color: colors.text,
            fontSize: sizes.text.s,
            fontWeight: '600',
            marginBottom: spacing.s,
        }
    })

    const screenProps = {
        contentStyle: {backgroundColor: colors.bg.secondary},
    }

    return {theme, colors, spacing, borderRadius, screenProps, isMobile}
}

export default useTheme