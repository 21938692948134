import React from 'react';
import {TextInput} from "react-native";
import useInputTheme from "./hooks/useInputTheme";

function Input({style = {}, ...otherProps}) {
    const {inputTheme} = useInputTheme()

    return (
        <TextInput style={[inputTheme.input, style]} {...otherProps} />
    )
}

export default Input;