import React from 'react';
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import useTheme from "../../hooks/useTheme";
import Overview from "./Overview";
import Button from "../../components/Button";
import OverviewHeaderButtons from "./OverviewHeaderButtons";
import CreateProject from "../Shared/CreateProject";
import Header from "../../components/Header";
import CreateTask from "../Shared/CreateTask";

const Stack = createNativeStackNavigator()

function OverviewStack(props) {
    const {screenProps} = useTheme()
    return (
        <Stack.Navigator
            initialRouteName={'Projects'}
            screenOptions={{
                header: props => <Header {...props} />,
                ...screenProps
            }}
        >
            <Stack.Screen name="Overview" component={Overview} options={{headerRight: () => <OverviewHeaderButtons/>}}/>
            <Stack.Screen name="CreateProject" component={CreateProject} options={{title: 'New Project'}}/>
            <Stack.Screen name="CreateTask" component={CreateTask} options={() => ({title: 'New Task'})}/>
        </Stack.Navigator>
    );
}

export default OverviewStack;