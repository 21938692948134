import React from 'react'
import Ionicons from '@expo/vector-icons/Ionicons';

const getIconName = (name) => {
    // Todo: Use custom icon set
    switch (name) {
        case 'tracks':
            return 'timer-outline'
        case 'projects':
            return 'apps-outline'
        case 'play':
            return 'play-outline'
        case 'stop':
            return 'stop-outline'
        case 'is-running':
            return 'timer-outline'
        case 'share':
            return 'share-outline'
        case 'info':
            return 'information-circle-outline'
        case 'success':
            return 'checkmark-circle-outline'
        case 'plus':
            return 'add-outline'
        case 'chevron-right':
            return 'chevron-forward-outline'
        case 'chevron-down':
            return 'chevron-down-outline'
        default:
            return 'albums-outline'
    }
}

const Icon = ({name, color, size, ...otherProps}) => {
    const iconName = getIconName(name)

    return (
      <Ionicons name={iconName} color={color} size={size} {...otherProps} />
    );
}

export default Icon