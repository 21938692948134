import React from 'react';
import {Pressable, ScrollView, Text, useWindowDimensions, View} from "react-native";
import useTheme from "../../hooks/useTheme";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {getProjects} from "../../api/api";
import ProjectTile from "../../components/ProjectTile";
import {useNavigation} from "@react-navigation/native";
import Grid from "../../components/_Layout/Grid";


function Projects({navigation}) {
    const teamId = null
    const {theme, colors, spacing, borderRadius} = useTheme()

    const windowDimensions = useWindowDimensions()
    const {width} = windowDimensions
    const columnCount = width > 980 ? 3 : width > 440 ? 2 : 1

    const query = useQuery({queryKey: ['projects', teamId], queryFn: getProjects})

    // Todo: Loading Component
    return query.isLoading ? null : (
        <ScrollView>
            <View style={theme.containerWide}>
                <Grid style={{width: '100%'}} cols={columnCount}>
                    {query.data && query.data.length > 0 ? query.data?.map((project, i) =>
                        <ProjectTile key={i} project={project} onPress={() => navigation.navigate('Project', {slug: project.slug})} />) : null
                    }
                </Grid>
            </View>
        </ScrollView>
    )
}

export default Projects;