const tabNavScreenOptions = ({colors}) => ({
    tabBarActiveTintColor: colors.text,
    tabBarInactiveTintColor: colors.subtext,
    tabBarActiveBackgroundColor: colors.subbg,
    tabBarInactiveBackgroundColor: colors.background,
    tabBarShowLabel: false,
    tabBarStyle: {
        borderTopWidth: 0,
    },
    headerStyle: {
        shadowColor: 'transparent',
    }
})

export default tabNavScreenOptions