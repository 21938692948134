import {View} from 'react-native'
import {NavigationContainer} from '@react-navigation/native'
import useNavigatorTheme from "./hooks/useNavigatorTheme"
import useTheme from "./hooks/useTheme";
import useTauri from "./hooks/useTauri";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import Login from "./screens/Login";
import Tabs from "./screens/Tabs";
import useLogin from "./hooks/useLogin";
import {useState, useEffect} from "react";

if (process.browser) {
    // @ts-ignore
    window._frameTimestamp = null
}

const Stack = createNativeStackNavigator()

const App = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [hasUserData, setHasUserData] = useState(false)
    const {colors, theme} = useTheme()
    const navigatorTheme = useNavigatorTheme()
    const queryClient = new QueryClient()
    const {isLoggedIn} = useLogin()

    const {invoke} = useTauri()

    useEffect(() => {
        isLoggedIn().then((isLoggedIn) => {
            setHasUserData(isLoggedIn)
            setIsLoading(false)
        })
    }, [])

    invoke('greet', {name: 'World'})
        // `invoke` returns a Promise
        .then((response) => console.log(response))

    return isLoading ? null : (
        <QueryClientProvider client={queryClient}>
            <View style={{width: '100%', flex: 1, backgroundColor: colors.bg.primary, overflow: 'hidden'}}>
                <NavigationContainer theme={navigatorTheme}>
                    <Stack.Navigator screenOptions={{headerShown: false}} initialRouteName={hasUserData ? 'Tabs' : 'Login'}>
                        <Stack.Screen name="Tabs" component={Tabs}/>
                        <Stack.Screen name="Login" component={Login}/>
                    </Stack.Navigator>
                </NavigationContainer>
            </View>
        </QueryClientProvider>
    )
}

export default App
