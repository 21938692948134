const devConfig = {
    siteURL: 'http://awork-killer-backend.test',
}

const prodConfig = {
    ...devConfig, // zur Sicherheit, falls ich mal einen Key vergesse :D
    siteURL: 'https://gehtdichnichtsan.renehenrich.de',
}

const config = __DEV__ ? devConfig : prodConfig

export default config
