import useTheme from "./useTheme";
import {DefaultTheme} from "@react-navigation/native";

const useNavigatorTheme = () => {
    const {colors} = useTheme()
    return {
        ...DefaultTheme,
        colors: {
            ...DefaultTheme.colors,
            primary: colors.primary,
            background: colors.background,
            card: colors.card,
            text: colors.text,
            border: colors.border,
            notification: colors.accent,
        },
    }
}

export default useNavigatorTheme