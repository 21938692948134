import React, {useEffect} from 'react';
import {Image, ScrollView, Text, View} from "react-native";
import useTheme from "../../hooks/useTheme";
import {useQuery} from "@tanstack/react-query";
import {getProject} from "../../api/api";

function Project({navigation, route}) {
    const {slug} = route.params
    const {theme, spacing} = useTheme()


    const {data = {}, isLoading = true} = useQuery({queryKey: ['project', slug], queryFn: async () => await getProject({slug})})

    const {title, description} = data

    useEffect(() => {
        if(data) navigation.setParams({name: data.title})
    }, [data])

    return isLoading ? null : (
        <ScrollView>
            <View style={{width: '100%', minHeight: 250, justifyContent: 'center', paddingVertical: spacing.xl}}>
                <Image
                    style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}
                    source={{uri: 'https://placekitten.com/1024/600'}}
                />
                <View style={theme.container}>
                    {title ? <Text style={theme.title}>{title}</Text> : null}
                    {description ? <Text style={theme.text}>{description}</Text> : null}
                </View>
            </View>
        </ScrollView>
    );
}

export default Project;