import React, {useState} from 'react';
import {Text, View} from "react-native";
import useTheme from "../../hooks/useTheme";
import Input from "../../components/Input";
import Button from "../../components/Button";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {postProject} from "../../api/api";
import {useToastStore} from "../../stores/useToastStore";

function CreateProject({navigation}) {
    const teamId = null
    const {theme} = useTheme()
    const {addToast} = useToastStore()
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    const queryClient = useQueryClient()

    const postProjectMutation = useMutation({
        mutationFn: postProject,
        onSuccess: () => {
            addToast({message: 'Project created successfully'})
            queryClient.invalidateQueries({queryKey: ['projects', teamId]}).then(() => {
                navigation?.navigate('Projects')
            })
        }
    })

    return (
        <View style={theme.container}>
            <Text style={theme.title}>Projekt anlegen</Text>
            <Input
                style={{width: '100%'}}
                editable
                placeholder="Titel"
                onChangeText={setTitle}
                value={title}
                textContentType="text"
                keyboardType="text"
            />
            <Input
                style={{width: '100%'}}
                editable
                placeholder="Beschreibung"
                onChangeText={setDescription}
                value={description}
                textContentType="text"
                keyboardType="text"
            />
            <Button label='Projekt anlegen' onPress={() => postProjectMutation.mutate({
                title,
                description,
                // Todo: Ins richtige Team posten
            })}/>
        </View>
    );
}

export default CreateProject;