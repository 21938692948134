import {create} from "zustand";
import {useColorScheme} from "react-native";
import { persist, createJSONStorage } from 'zustand/middleware'

export const useThemeSettings = create(persist((set, get) => ({
    baseFontSize: 16,
    userThemePreference: 'auto',
    setUserThemePreference: (userThemePreference: 'auto' | 'light' | 'dark') => set({userThemePreference}),
    userTheme: () => {
        const preference = get().userThemePreference
        const colorScheme = useColorScheme()
        return preference === 'auto' ? colorScheme : preference
    }
}), {
    name: 'theme-settings'
}))